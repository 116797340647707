import { Avatar, Box, CardHeader, Divider, styled } from "@mui/material";
import { colors } from "common";
import BazarCard from "components/BazarCard";
import { H3, Paragraph, Tiny } from "components/Typography";
import { FlexBox } from "../flex-box";
import React from "react";
import BazarRating from "components/BazarRating";
import { Review } from "common/types/review";
import Link from "next/link";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import GoogleIcon from "@mui/icons-material/Google";

const StyledBazarCard = styled(BazarCard)(() => ({
  height: "100%",
  margin: "auto",
  display: "flex",
  overflow: "hidden",
  borderRadius: "8px",
  position: "relative",
  flexDirection: "column",
  justifyContent: "space-between",
  transition: "all 250ms ease-in-out",
}));

const ContentWrapper = styled(Box)(() => ({
  padding: "1rem",
  "& .title, & .categories": {
    overflow: "hidden",
    whiteSpace: "normal",
    textOverflow: "ellipsis",
  },
}));

// ========================================================
type Props = {
  review: Review;
  clickable?: boolean;
};
// ========================================================

const ReviewCard: React.FC<Props> = ({ review, clickable = true }) => {
  const ReviewAvatar = () => {
    return (
      <Avatar
        src={
          review?.image_link ||
          `/assets/images/fb-review-avatars/${review?.review_id}.jpg`
        }
        sx={{ bgcolor: colors.primary }}
      >
        {review?.customer_name?.[0]}
      </Avatar>
    );
  };

  const ReviewActionIcon = () => {
    if (review?.type === "google") {
      return <GoogleIcon />;
    }
    return <FacebookRoundedIcon />;
  };

  return (
    <Link
      style={{ pointerEvents: !clickable ? "none" : undefined }}
      href={review?.link}
      passHref
    >
      <a
        style={{ pointerEvents: !clickable ? "none" : undefined }}
        target={"_blank"}
        rel={"noopener noreferrer"}
      >
        <StyledBazarCard hoverEffect>
          <CardHeader
            avatar={ReviewAvatar()}
            title={review?.customer_name}
            subheader={review?.created_time}
            action={ReviewActionIcon()}
          />
          <Divider />
          <ContentWrapper sx={{ height: 160 }}>
            <BazarRating size="small" value={5} color="warn" readOnly />
            <FlexBox>
              <Box flex="1 1 0" minWidth="0px" mr={1}>
                <H3
                  mb={1}
                  fontSize="14px"
                  fontWeight="600"
                  className="title"
                  color="text.secondary"
                  sx={{
                    display: "-webkit-box",
                    overflow: "hidden",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 5,
                  }}
                >
                  {review.review_text}
                </H3>
              </Box>
            </FlexBox>
          </ContentWrapper>
        </StyledBazarCard>
      </a>
    </Link>
  );
};

export default ReviewCard;
